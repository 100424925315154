<template>
    <main-header></main-header>
    <div class="servie_details">
        <div class="content">
            <div class="left">
                <div class="info" v-for="(data,index) in details" :key="index">
                    <div class="image"><img :src="data.image" :alt="data.slug"></div>
                    <h1 class="title">{{ data.title }}</h1>
                    <div class="description">{{ data.description }}</div>
                </div>
            </div>
            <div class="right">
                <div class="search">
                    <form action="">
                        <input type="text" placeholder="Search...">
                        <button type="submit">Search</button>
                    </form>
                </div>
                <div class="category">
                    <h2>Categories</h2>
                    <ul class="items">
                        <li class="item" v-for="(data,index) in categories" :key="index">
                            <router-link :to="'/category/'+data.slug" class="link">{{ data.title }}</router-link>
                        </li>
                    </ul>
                </div>
                <div class="recent_post">
                    <h2>Recent Post</h2>
                    <div class="items">
                        <div class="item" v-for="(data,index) in recent_post" :key="index">
                            <div class="thumb">
                                <img :src="data.image" :alt="data.slug">
                            </div>
                            <div class="content">
                                <router-link :to="'/'+data.slug" class="title" @click="recentPost(data.slug)">{{ data.title }}</router-link>
                                <div class="des">{{ data.description }}</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <main-footer></main-footer>
</template>
<script>
import axios from 'axios';
import { ref, onMounted } from 'vue';
import { useRoute } from 'vue-router';
import MainHeader from "../layout/MainHeader.vue"
import MainFooter from "../layout/MainFooter.vue"

export default {
    name: "service-details",
    components: { MainHeader, MainFooter },
    setup(){
        const route = useRoute();
        const details = ref();
        const recent_post = ref([]);
        const categories = ref([]);

        onMounted(() => {
            axios.get('/api/details/'+route.params.slug)
            .then(response => {
                if(response.status == 200){
                    details.value = response.data;
                }
            })

            axios.get('/api/recentpost')
            .then(response => {
                if(response.status == 200){
                    recent_post.value = response.data;
                }
            })

            axios.get('/api/categories')
            .then(response => {
                if(response.status == 200){
                    categories.value = response.data;
                }
            })
        })

        const recentPost = (slug) => {
            axios.get('/api/details/'+slug)
            .then(response => {
                if(response.status == 200){
                    details.value = response.data;
                }
            })
        }

        return {
            details,
            recent_post,
            recentPost,
            categories,
        }
    }
}
</script>
<style lang="scss">
.servie_details {
    .content {
        display: flex;
        column-gap: 8px;
        .left {
            width: calc(100% - 300px);
            .info {
                padding: 20px;
                .image {
                    max-width: 100%;
                    width: 100%;
                    display: flex;
                    justify-content: center;
                    img {
                        max-width: 100%;
                    }
                }
                .title {
                    font-size: 32px;
                    color: #444;
                    margin-top: 20px;
                    margin-bottom: 12px;
                }
                .description {
                    color: #777;
                    font-size: 15px;
                    line-height: 28px;
                }
            }
        }
        .right {
            width: 300px;
            padding: 20px;
            .search {
                background-color: #fff;
                box-shadow: 0 4px 20px rgba(0,0,0,.15);
                padding: 20px 12px;
                border-radius: 4px;
                form {
                    border: 1px solid #ddd;
                    display: flex;
                    align-items: center;
                    input {
                        padding: 12px 8px;
                        border: none;
                        outline: none;
                    }
                    button {
                        border: none;
                        outline: none;
                        cursor: pointer;
                        color: #222;
                        font-weight: 600;
                        padding: 12px;
                        border-radius: 4px;
                        display: inline-block;
                        background: linear-gradient(45deg, #ffb1b1, #adc744);
                        transition: all 0.3s ease 0s;
                        -webkit-transition: all 0.3s ease 0s;
                        &:hover {
                            background: linear-gradient(45deg, #ff9e9e, #adca36);
                            transition: all 0.3s ease 0s;
                            -webkit-transition: all 0.3s ease 0s;
                        }
                    }
                }
                
            }
            .category {
                background-color: #fff;
                box-shadow: 0 4px 20px rgba(0,0,0,.15);
                padding: 20px 12px;
                border-radius: 4px;
                margin-top: 30px;
                & > h2 {
                    color: #444;
                    font-size: 16px;
                    border-bottom: 1px solid #ddd;
                    padding-bottom: 8px;
                    margin-bottom: 8px;
                }
                .items {
                    list-style: none;
                    .item {
                        line-height: 26px;
                        .link {
                            text-decoration: none;
                            color: #777;
                            padding: 4px 12px;
                            display: block;
                            font-size: 14px;
                            &.router-link-active {
                                color: #adc744;
                            }
                            &:hover {
                                background-color: #eee;
                            }
                        }
                    }
                }
            }
            .recent_post {
                background-color: #fff;
                box-shadow: 0 4px 20px rgba(0,0,0,.15);
                padding: 20px 12px;
                border-radius: 4px;
                margin-top: 30px;
                & > h2 {
                    color: #444;
                    font-size: 16px;
                    border-bottom: 1px solid #ddd;
                    padding-bottom: 8px;
                    margin-bottom: 8px;
                }
               .items {
                display: flex;
                flex-direction: column;
                row-gap: 12px;
                    .item {
                        display: flex;
                        column-gap: 8px;
                        row-gap: 12px;
                        .thumb {
                            width: 60px;
                            height: 50px;
                            img {
                                max-width: 100%;
                            }
                        }
                        .content {
                            display: flex;
                            flex-direction: column;
                            .title {
                                text-decoration: none;
                                font-size: 16px;
                                color: #444;
                                transition: all 0.3s ease 0s;
                                -webkit-transition: all 0.3s ease 0s;
                                &:hover {
                                    color: #adc744;
                                }
                            }
                            .des {
                                color: #777;
                                font-size: 14px;
                                margin-top: 6px;
                            }
                        }
                    }
               } 
            }
        }
    }
}
</style>