import Axios from "axios";
const RESOURCE_NAME = '/api';


export default {
    sendmail(data){
        return Axios.post(`${RESOURCE_NAME}/forget-password`,data);
    },
    resetpassword(data){
        return Axios.post(`${RESOURCE_NAME}/reset-password`,data);
    },
}