<template>
    <main-header></main-header>
     <div class="service_page">
        <div class="heading">
            <h2>Category Services</h2>
        </div>
        <div class="items" v-if="service_data.length">
            <div class="item" v-for="(data,index) in service_data" :key="index">
                <div class="icon">
                    <div class="_i">
                        <img :src="data.image" :alt="data.slug">
                    </div>
                    <span class="_t"></span>
                    <span class="_b"></span>
                </div>
                <div class="content">
                    <router-link :to="'/'+data.slug" class="title">{{ data.title }}</router-link>
                    <div class="txt">{{ data.description }}</div>
                </div>
            </div>
        </div>
        <p v-else>Data Not Found!!</p>
    </div>
    <main-footer></main-footer>
</template>
<script>
import axios from 'axios';
import { ref, onMounted } from 'vue';
import { useRoute } from 'vue-router';
import MainHeader from "@/views/layout/MainHeader.vue"
import MainFooter from "@/views/layout/MainFooter.vue"

export default {
    name: "category-list",
    components: { MainHeader, MainFooter },
    setup(){
        const route = useRoute();
        const service_data = ref([]);

        onMounted(() => {
            axios.get('/api/categories/'+route.params.slug)
            .then(response => {
                if(response.status == 200){
                    service_data.value = response.data;
                }
            })
        })

        return {
            service_data,
        }
    }
}
</script>
<style lang="scss">
/* ========================================= */
/* Service Css */
.service_page {
    margin-top: 60px;
    padding: 40px;
    .heading {  
        padding: 8px 20px;
        margin-bottom: 20px;
        h2 {
            display: table;
            margin: auto;
            position: relative;
            z-index: 1;
            &::before,&::after {
                position: absolute;
                content: "";
                width: 40px;
                height: 25px;
                background-color: rgba(241,144,36,.439);
                z-index: -1;
            }
            &::before {
                top: -10px;
                left: 7px;
                border-radius: 70px 20px 70px 8px;
                transform: rotate(-5deg);
            }
            &::after {
                bottom: -16px;
                left: 40px;
                border-radius: 8px 70px 20px 70px;
                transform: rotate(5deg);
            }
        }
    }
    .items {
        display: grid;
        grid-template-columns: repeat(4, minmax(150px, 1fr));
        column-gap: 30px;
        row-gap: 40px;
        .item {
            border-radius: 4px;
            padding: 40px 20px;
            overflow: hidden;
            position: relative;
            border: 1px solid #f5f5f5;
            transition: all 0.3s ease 0s;
            -webkit-transition: all 0.3s ease 0s;
            &:hover {
                background-color: #f2f2f2;
                transform: translateY(-8px);
                box-shadow: 0 4px 20px rgba(0, 0, 0, 0.15);
                .icon {
                    ._t {
                        &::before {
                            width: 50%;
                            right: 0%;
                            transform: translateX(0%);
                            background-color: #5648ed;
                        }
                        &::after {
                            width: 50%;
                            left: 0%;
                            transform: translateX(0%);
                            background-color: #5648ed;
                        }
                    }
                    ._b {
                        &::before {
                            width: 50%;
                            right: 0%;
                            transform: translateX(0%);
                            background-color: #5648ed;
                        }
                        &::after {
                            width: 50%;
                            left: 0%;
                            transform: translateX(0%);
                            background-color: #5648ed;
                        }
                    }
                }
            }
            &::after {
                position: absolute;
                content: "";
                width: 0%;
                height: 3px;
                bottom: 0;
                left: 0;
                background-color: transparent;
                transition: all 0.3s ease 0s;
                -webkit-transition: all 0.3s ease 0s;
            }
            &:hover::after {
                width: 100%;
                background-color: #5648ed;
            }
            .icon {
                display: table;
                margin: auto;
                ._i {
                    width: 60px;
                    height: 60px;
                    border-radius: 50%;
                    background: linear-gradient(90deg,#86d169 ,#41b782);
                    overflow: hidden;
                    img {
                        max-width: 100%;
                    }
                }
                ._t {
                    width: 50px;
                    height: 2px;
                    background-color: #999;
                    display: table;
                    margin: auto;
                    margin-top: 4px;
                    position: relative;
                    &::before {
                        position: absolute;
                        content: '';
                        width: 0%;
                        height: 2px;
                        right: 50%;
                        transform: translateX(-50%);
                        background-color: transparent;
                        transition: all 0.5s ease 0s;
                        -webkit-transition: all 0.5s ease 0s;
                    }
                    &::after {
                        position: absolute;
                        content: '';
                        width: 3px;
                        height: 2px;
                        left: 50%;
                        transform: translateX(-50%);
                        background-color: transparent;
                        transition: all 0.5s ease 0s;
                        -webkit-transition: all 0.5s ease 0s;
                    }
                }
                ._b {
                    width: 30px;
                    height: 2px;
                    background-color: #999;
                    display: table;
                    margin: auto;
                    margin-top: 4px;
                    position: relative;
                    &::before {
                        position: absolute;
                        content: '';
                        width: 0%;
                        height: 2px;
                        right: 50%;
                        transform: translateX(-50%);
                        background-color: transparent;
                        transition: all 0.5s ease 0s;
                        -webkit-transition: all 0.5s ease 0s;
                    }
                    &::after {
                        position: absolute;
                        content: '';
                        width: 3px;
                        height: 2px;
                        left: 50%;
                        transform: translateX(-50%);
                        background-color: transparent;
                        transition: all 0.5s ease 0s;
                        -webkit-transition: all 0.5s ease 0s;
                    }
                }
            }
            .content {
                margin-top: 16px;
                text-align: center;
                .title {
                    font-size: 20px;
                    font-weight: 600;
                    color: #444;
                    margin-bottom: 12px;
                    text-decoration: none;
                    transition: all 0.3s ease 0s;
                    -webkit-transition: all 0.3s ease 0s;
                    &:hover {
                        color: #86d169;
                    }
                }
                .txt {
                    font-size: 14px;
                    color: #777;
                }
            }
        }
    }
}
</style>