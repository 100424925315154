import Axios from "axios";
const RESOURCE_NAME = '/api/irc';


export default {
    ircData(){
        return Axios.get(`${RESOURCE_NAME}/irc`);
    },
    nobayonData(){
        return Axios.get(`${RESOURCE_NAME}/nobayon`);
    },
    roptaniData(){
        return Axios.get(`${RESOURCE_NAME}/roptani`);
    },
    formalinData(){
        return Axios.get(`${RESOURCE_NAME}/formalin`);
    },
    aropitoData(){
        return Axios.get(`${RESOURCE_NAME}/aropito`);
    },
    vektigotData(){
        return Axios.get(`${RESOURCE_NAME}/vektigot`);
    },
}