const AdminModule = () => import("./AdminModule.vue");
const Home = () => import("./views/HomePage.vue");
// Category
const CategoryList = () => import("./views/category/CategoryList.vue");
const CategoryAdd = () => import("./views/category/CategoryAdd.vue");
const CategoryEdit = () => import("./views/category/CategoryEdit.vue");
// Slider
const SliderList = () => import("./views/slider/SliderList.vue");
const SliderAdd = () => import("./views/slider/SliderAdd.vue");
const SliderEdit = () => import("./views/slider/SliderEdit.vue");
// Service
const ServiceList = () => import("./views/service/ServiceList.vue");
const ServiceAdd = () => import("./views/service/ServiceAdd.vue");
const ServiceEdit = () => import("./views/service/ServiceEdit.vue");

// RJSC
const RjscList = () => import("./views/rjsc/RjscList.vue");
const RjscAdd = () => import("./views/rjsc/RjscAdd.vue");
const RjscEdit = () => import("./views/rjsc/RjscEdit.vue");
// IRC
// Irc
const IrcCategoryList = () => import("./views/irc/category/CategoryList.vue");
const IrcCategoryAdd = () => import("./views/irc/category/CategoryAdd.vue");
const IrcCategoryEdit = () => import("./views/irc/category/CategoryEdit.vue");
// Irc
const IrcList = () => import("./views/irc/irc/IrcList.vue");
const IrcAdd = () => import("./views/irc/irc/IrcAdd.vue");
const IrcEdit = () => import("./views/irc/irc/IrcEdit.vue");
// Nobayon
const NobayonList = () => import("./views/irc/nobayon/NobayonList.vue");
const NobayonAdd = () => import("./views/irc/nobayon/NobayonAdd.vue");
const NobayonEdit = () => import("./views/irc/nobayon/NobayonEdit.vue");
// Roptani
const RoptaniList = () => import("./views/irc/roptani/RoptaniList.vue");
const RoptaniAdd = () => import("./views/irc/roptani/RoptaniAdd.vue");
const RoptaniEdit = () => import("./views/irc/roptani/RoptaniEdit.vue");
// Formalin
const FormalinList = () => import("./views/irc/formalin/FormalinList.vue");
const FormalinAdd = () => import("./views/irc/formalin/FormalinAdd.vue");
const FormalinEdit = () => import("./views/irc/formalin/FormalinEdit.vue");
// Aropito
const AropitoList = () => import("./views/irc/aropito/AropitoList.vue");
const AropitoAdd = () => import("./views/irc/aropito/AropitoAdd.vue");
const AropitoEdit = () => import("./views/irc/aropito/AropitoEdit.vue");
// Vektigot
const VektigotList = () => import("./views/irc/vektigot/VektigotList.vue");
const VektigotAdd = () => import("./views/irc/vektigot/VektigotAdd.vue");
const VektigotEdit = () => import("./views/irc/vektigot/VektigotEdit.vue");

const moduleRoute = {
  path: "/admin",
  component: AdminModule,
  children: [
    { 
      path:"/admin", 
      name:"admin-home ",
      component:Home,
      meta: {AdminLogin: true}
    },
    // category
    { 
      path:"/admin/category", 
      name:"admin-category",
      component:CategoryList
    },
    { 
      path:"/admin/category/add", 
      name:"admin-category-add",
      component:CategoryAdd
    },
    { 
      path:"/admin/category/edit/:id", 
      name:"admin-category-edit",
      component:CategoryEdit
    },
    // slider
    { 
      path:"/admin/slider", 
      name:"admin-slider",
      component:SliderList
    },
    { 
      path:"/admin/slider/add", 
      name:"admin-slider-add",
      component:SliderAdd
    },
    { 
      path:"/admin/slider/edit/:id", 
      name:"admin-slider-edit",
      component:SliderEdit
    },
    // service
    { 
      path:"/admin/service", 
      name:"admin-service",
      component:ServiceList
    },
    { 
      path:"/admin/service/add", 
      name:"admin-service-add",
      component:ServiceAdd
    },
    { 
      path:"/admin/service/edit/:id", 
      name:"admin-service-edit",
      component:ServiceEdit
    },
    // rjsc
    { 
      path:"/admin/rjsc", 
      name:"admin-rjsc",
      component:RjscList
    },
    { 
      path:"/admin/rjsc/add", 
      name:"admin-rjsc-add",
      component:RjscAdd
    },
    { 
      path:"/admin/rjsc/edit/:id", 
      name:"admin-rjsc-edit",
      component:RjscEdit
    },
    // IRC
    // Categories
    { 
      path:"/admin/irc/categories", 
      name:"admin-irc-category",
      component:IrcCategoryList
    },
    { 
      path:"/admin/irc/categories/add", 
      name:"admin-irc-category-add",
      component:IrcCategoryAdd
    },
    { 
      path:"/admin/irc/categories/edit/:id", 
      name:"admin-irc-category-edit",
      component:IrcCategoryEdit
    },
    // Irc
    { 
      path:"/admin/irc/irc", 
      name:"admin-irc",
      component:IrcList
    },
    { 
      path:"/admin/irc/irc/add", 
      name:"admin-irc-add",
      component:IrcAdd
    },
    { 
      path:"/admin/irc/irc/edit/:id", 
      name:"admin-irc-edit",
      component:IrcEdit
    },
    // Nobayon
    { 
      path:"/admin/irc/nobayon", 
      name:"admin-nobayon",
      component:NobayonList
    },
    { 
      path:"/admin/irc/nobayon/add", 
      name:"admin-nobayon-add",
      component:NobayonAdd
    },
    { 
      path:"/admin/irc/nobayon/edit/:id", 
      name:"admin-nobayon-edit",
      component:NobayonEdit
    },
    // Roptani
    { 
      path:"/admin/irc/roptani", 
      name:"admin-roptani",
      component:RoptaniList
    },
    { 
      path:"/admin/irc/roptani/add", 
      name:"admin-roptani-add",
      component:RoptaniAdd
    },
    { 
      path:"/admin/irc/roptani/edit/:id", 
      name:"admin-roptani-edit",
      component:RoptaniEdit
    },
    // Formalin
    { 
      path:"/admin/irc/formalin", 
      name:"admin-formalin",
      component:FormalinList
    },
    { 
      path:"/admin/irc/formalin/add", 
      name:"admin-formalin-add",
      component:FormalinAdd
    },
    { 
      path:"/admin/irc/formalin/edit/:id", 
      name:"admin-formalin-edit",
      component:FormalinEdit
    },
    // Aropito
    { 
      path:"/admin/irc/aropito", 
      name:"admin-aropito",
      component:AropitoList
    },
    { 
      path:"/admin/irc/aropito/add", 
      name:"admin-aropito-add",
      component:AropitoAdd
    },
    { 
      path:"/admin/irc/aropito/edit/:id", 
      name:"admin-aropito-edit",
      component:AropitoEdit
    },
    // Vektigot
    { 
      path:"/admin/irc/vektigot", 
      name:"admin-vektigot",
      component:VektigotList
    },
    { 
      path:"/admin/irc/vektigot/add", 
      name:"admin-vektigot-add",
      component:VektigotAdd
    },
    { 
      path:"/admin/irc/vektigot/edit/:id", 
      name:"admin-vektigot-edit",
      component:VektigotEdit
    },
  ]
};

export default router => {
  router.addRoute(moduleRoute);
};

