<template>
    <div class="footer">
        <p>&copy; Copyright By OnlineZone</p>
        <ul>
            <li><router-link to="/terms-condition">Terms & Condition</router-link></li>
            <li><router-link to="/privacy-policy">Privacy Policy</router-link></li>
        </ul>
    </div>
</template>
<style lang="scss">
.footer {
    height: 50px;
    background: linear-gradient(90deg,#86d169 ,#41b782);
    display: flex;
    align-items: center;
    padding: 0 30px;
    justify-content: space-between;
    p {
        margin: 0;
        color: #fff;
    }
    ul {
        display: flex;
        column-gap: 12px;
        list-style: none;
        li {
            a {
                color: #fff;
                font-size: 14px;
                &:hover {
                    color: #d89024;
                }
            }
        }
    }
}

$phone-width: 575px;

@media (max-width: #{$phone-width}) {
    .footer {
        display: block;
        text-align: center;
        p {
            font-size: 12px;
            padding-top: 4px;
        }
        ul {
            justify-content: center;
            li {
                a {
                    font-size: 12px;
                }
            }
        }
    }
}

</style>