<template>
    <main-header></main-header>

    <div class="irc">
        <div class="content">
            <div class="step one">
                <div class="heading">
                    <h2>পরিষেবার নাম : বাণিজ্যিক ও শিল্প আই আর সি </h2>
                    <p>[ছক '১' ]</p>
                </div>
                <ul class="lists">
                    <li class="list head">
                        <div class="title">আমদানি সীমা</div>
                        <div class="title">নিবন্ধন ফি</div>
                        <div class="title">নবায়ন ফি</div>
                    </li>
                    <li class="list head">
                        <div class="title"></div>
                        <div class="_l">
                            <div class="price">বিদ্যমান</div>
                            <div class="price">নতুন</div>
                        </div>
                        <div class="_r">
                            <div class="price">বিদ্যমান</div>
                            <div class="price">নতুন</div>
                        </div>
                    </li>
                    <li class="list" v-for="(data,index) in get_irc" :key="index">
                        <div class="title">{{ data.title }}</div>
                        <div class="_l">
                            <div class="price">{{ data.present }}</div>
                            <div class="price">{{ data.new }}</div>
                        </div>
                        <div class="_r">
                            <div class="price">{{ data.present_one }}</div>
                            <div class="price">{{ data.new_one }}</div>
                        </div>
                    </li>
                </ul>
            </div>
            <div class="step two">
                <div class="heading">
                    <h2>পরিষেবার নাম : প্রস্তাবিত ও অনুমোদিত সারচার্জ (নিবন্ধন সনদ নবায়নে ব্যর্থ হলে )</h2>
                </div>
                <ul class="lists">
                    <li class="list head">
                        <div class="title item">সময়সীমা</div>
                        <div class="title item">আমদানিকারক ( বাণিজ্যিক ও শিল্প )</div>
                        <div class="title item">রপ্তানিকারক</div>
                        <div class="title item">রপ্তানিকারক ( ইন্ডেটিং )</div>
                    </li>
                    <li class="list" v-for="(data,index) in get_nobayon" :key="index">
                        <div class="title item">{{ data.title }}</div>
                        <div class="price item">{{ data.one }}</div>
                        <div class="price item">{{ data.two }}</div>
                        <div class="price item">{{ data.three }}</div>
                    </li>
                </ul>
            </div>
            <div class="step three">
                <div class="heading">
                    <h2>পরিষেবার নাম : বিভিন্ন ধরণের রপ্তানি সনদ ফি </h2>
                </div>
                <ul class="lists">
                    <li class="list head">
                        <div class="sl item">ক্রমিক</div>
                        <div class="title item">আমদানি সীমা</div>
                        <div class="title item">নিবন্ধন ফি</div>
                        <div class="title item">নবায়ন ফি</div>
                    </li>
                    <li class="list head">
                        <div class="sl item"></div>
                        <div class="title item"></div>
                        <div class="_l item">
                            <div class="price">বিদ্যমান</div>
                            <div class="price">নতুন</div>
                        </div>
                        <div class="_r item">
                            <div class="price">বিদ্যমান</div>
                            <div class="price">নতুন</div>
                        </div>
                    </li>
                    <li class="list" v-for="(data,index) in get_roptani" :key="index">
                        <div class="sl item">{{ index+1 }}</div>
                        <div class="title item">{{ data.title }}</div>
                        <div class="_l item">
                            <div class="price">{{ data.present }}</div>
                            <div class="price">{{ data.new }}</div>
                        </div>
                        <div class="_r item">
                            <div class="price">{{ data.present_one }}</div>
                            <div class="price">{{ data.new_one }}</div>
                        </div>
                    </li>
                </ul>
            </div>
            <div class="step four">
                <div class="heading">
                    <h2>পরিষেবার নাম : ফরমালিন উৎপাদন, আমদানি, মজুদ ও বিক্রয় লাইসেন্স ফি </h2>
                </div>
                <ul class="lists">
                    <li class="list head">
                        <div class="sl item">ক্রমিক</div>
                        <div class="title item">লাইসেন্সের ধরন</div>
                        <div class="title item">বিদ্যমান লাইসেন্স ফি ( টাকায় )</div>
                        <div class="title item">ধার্যকৃত নতুন ফি</div>
                    </li>
                    <li class="list" v-for="(data,index) in get_formalin" :key="index">
                        <div class="sl item">{{ index+1 }}</div>
                        <div class="price item">{{ data.title }}</div>
                        <div class="price item">{{ data.price }}</div>
                        <div class="price item">{{ data.new_price }}</div>
                    </li>
                </ul>
            </div>
            <div class="step five">
                <div class="heading">
                    <h2>পরিষেবার নাম : নতুন আরোপিত সেবা ফি </h2>
                </div>
                <ul class="lists">
                    <li class="list head">
                        <div class="sl item">ক্রমিক</div>
                        <div class="title item">বিভিন্ন ধরনের</div>
                        <div class="title item">ধার্যকৃত নতুন ফি</div>
                    </li>
                    <li class="list body" v-for="(data,index) in get_aropito" :key="index">
                        <div class="title">{{ data.title }}</div>
                        <ul class="_lists">
                            <li class="_list" v-for="(ar1, i) in data?.aropito" :key="i">
                                <div class="sl item">{{ i+1 }}</div>
                                <div class="price item">{{ ar1.title }}</div>
                                <div class="price item">{{ ar1.price }}</div>
                            </li>
                        </ul>
                    </li>
                </ul>
            </div>
            <div class="step six">
                <div class="heading">
                    <h2>পরিষেবার নাম : ব্যক্তিগত আগ্নেয়াস্ত্র আমদানির অনুমতি প্রধান [০১(এক ) বছর সময়কালের জন্য ]</h2>
                </div>
                <ul class="lists">
                    <li class="list head">
                        <div class="title item">প্রস্তাবিত ও অনুমোদিত</div>
                        <div class="title item">মন্তব্য</div>
                    </li>
                    <li class="list" v-for="(data,index) in get_vektigot" :key="index">
                        <div class="price item">{{ data.title }}</div>
                        <div class="price item">{{ data.price }}</div>
                        <div class="price item">{{ data.montobo }}</div>
                    </li>
                </ul>
            </div>
        </div>
    </div>
    
    <main-footer></main-footer>
</template>
<script>
import MainHeader from "./layout/MainHeader.vue"
import MainFooter from "./layout/MainFooter.vue"
import Api from "./composable/irc-api.js"
import { ref, onMounted } from "vue";
export default {
    name: "irc-page",
    components: { MainHeader, MainFooter },
    setup() {
        const get_irc = ref([]);
        const get_nobayon = ref([]);
        const get_roptani = ref([]);
        const get_formalin = ref([]);
        const get_aropito = ref([]);
        const get_vektigot = ref([]);

        onMounted( async() => {
            // Irc
            const ircData = await Api.ircData();
            get_irc.value = ircData.data;

            // Nobayon
            const nobayonData = await Api.nobayonData();
            get_nobayon.value = nobayonData.data;

            // Roptani
            const roptaniData = await Api.roptaniData();
            get_roptani.value = roptaniData.data;

            // Formalin
            const formalinData = await Api.formalinData();
            get_formalin.value = formalinData.data;

            // Aropito
            const aropitoData = await Api.aropitoData();
            get_aropito.value = aropitoData.data;
            console.log(get_aropito.value);

            // Vektigot
            const vektigotData = await Api.vektigotData();
            get_vektigot.value = vektigotData.data;
        })

        return {
            get_irc,
            get_nobayon,
            get_roptani,
            get_formalin,
            get_aropito,
            get_vektigot,
        }
    },

};
</script>

<style lang="scss">
.irc {
    display: flex;
    justify-content: center;
    padding: 40px 20px;
    .content {
        max-width: 800px;
        width: 100%;
        .step {
            margin-bottom: 30px;
            .heading {
                h2 {
                    font-size: 14px;
                    color: #444;
                }
                p {
                    font-size: 14px;
                    color: #444;
                    text-align: center;
                    margin-top: 8px;
                }
            }
            .lists {
                list-style: none;
                border: 1px solid #ddd;
                border-radius: 4px;
                margin-top: 20px;
                .list,._list {
                    display: flex;
                    column-gap: 12px;
                    border-bottom: 1px solid #ddd;
                    &:last-child {
                        border-bottom: 0px solid transparent;
                    }
                    &.head {
                        display: flex;
                        justify-content: center;
                        .sl, .title, .price {
                            font-size: 12px;
                            font-weight: 600;
                            text-align: center;
                        }
                    }
                    .sl, .title, .price {
                        color: #444;
                        font-size: 12px;
                        padding: 6px 12px;
                    }
                    .item {
                        border-right: 1px solid #ddd;
                        &:last-child {
                            border-right: 0px solid #ddd;
                        }
                    }
                }
            }
            &.one {
                .lists {
                    .list {
                        .title, ._l, ._r {
                            width: 33.3333%;
                            border-right: 1px solid #ddd;
                            &:last-child {
                                border-right: 0px solid transparent;
                            }
                        }
                        ._l, ._r {
                            display: flex;
                            .price {
                                width: 50%;
                                border-right: 1px solid #ddd;
                                &:last-child {
                                    border-right: 0px solid transparent;
                                }
                            }
                        }
                    }
                }
            }
            &.two {
                .lists {
                    .list {
                        .item {
                            &:nth-child(1) {
                                width: 40%;
                            }
                            &:nth-child(2),&:nth-child(3),&:nth-child(4) {
                                width: 20%;
                            }
                        }
                    }
                }
            }
            &.three {
                .lists {
                    .list {
                        .title, ._l, ._r {
                            border-right: 1px solid #ddd;
                            &:last-child {
                                border-right: 0px solid transparent;
                            }
                        }
                        ._l, ._r {
                            display: flex;
                            .price {
                                width: 50%;
                                border-right: 1px solid #ddd;
                                &:last-child {
                                    border-right: 0px solid transparent;
                                }
                            }
                        }
                        .item {
                            &:nth-child(1){
                                width: 10%;
                            }
                            &:nth-child(2){
                                width: 40%;
                            }
                            &:nth-child(3),&:nth-child(4){
                                width: 25%;
                            }
                        }
                    }
                }
            }
            &.four {
                .lists {
                    .list {
                        .item {
                            &:nth-child(1) {
                                width: 10%;
                            }
                            &:nth-child(2),&:nth-child(3),&:nth-child(4) {
                                width: 30%;
                            }
                        }
                    }
                }
            }
            &.five {
                .lists {
                    .list {
                        .item {
                            &:nth-child(1) {
                                width: 10%;
                            }
                            &:nth-child(2) {
                                width: 70%;
                            }
                            &:nth-child(3) {
                                width: 20%;
                            }
                        }
                        &.body {
                            flex-direction: column;
                            .title {
                                color: #000;
                                font-size: 16px;
                            }
                        }
                        ._lists {
                            width: 100%;
                            border-top: 1px solid #ddd;
                            ._list {
                                .item {
                                    &:nth-child(1) {
                                        width: 10%;
                                    }
                                    &:nth-child(2) {
                                        width: 70%;
                                    }
                                    &:nth-child(3) {
                                        width: 20%;
                                    }
                                }
                            }
                        }
                    }
                }
            }
            &.six {
                .lists {
                    .list {
                        &.head {
                            .item {
                                &:nth-child(1) {
                                    width: 70%;
                                }
                                &:nth-child(2) {
                                    width: 30%;
                                }
                            }
                        }
                        .item {
                            &:nth-child(1), &:nth-child(2) {
                                width: 35%;
                            }
                            &:nth-child(3) {
                                width: 30%;
                            }
                        }
                    }
                }
            }
        }
    }
}
</style>