<template>
    <main-header></main-header>
    <div class="form_area login">
        <div class="form_data">
            <h2 class="heading">Reset Password</h2>
            <form @submit.prevent="formSubmit">
                <div class="_gp">
                    <label>Email</label>
                    <input type="email" v-model="email" placeholder="example@gmail.com">
                </div>
                <div class="_gp">
                    <label>New Password</label>
                    <div class="hide_show">
                        <input :type="pass_show_hide" v-model="new_password" placeholder="*****">
                        <img v-if="pass_show_hide == 'text'" @click="PassShowHideBtn('password')" src="@/assets/image/icon/show.png" alt="Show Password">
                        <img v-else @click="PassShowHideBtn('text')" src="@/assets/image/icon/hide.png" alt="Hide Password">
                    </div>
                </div>
                <div class="_gp">
                    <label>Confirm Password</label>
                    <div class="hide_show">
                        <input :type="reset_pass_show_hide" v-model="cnf_password" placeholder="*****">
                        <img v-if="reset_pass_show_hide == 'text'" @click="ResetPassShowHideBtn('password')" src="@/assets/image/icon/show.png" alt="Show Password">
                        <img v-else @click="ResetPassShowHideBtn('text')" src="@/assets/image/icon/hide.png" alt="Hide Password">
                    </div>
                </div>
                <div class="actions">
                    <button :type="action_type"><div class="loader" v-if="action_type == 'button'"></div> Email Password Reset Link</button>
                </div>
            </form>
        </div>
    </div>
    <main-footer></main-footer>
</template>
<script>
import { ref, onMounted } from 'vue';
import { useRouter } from 'vue-router';
import MainHeader from "@/views/layout/MainHeader.vue"
import MainFooter from "@/views/layout/MainFooter.vue"
import Api from '@/views/forget/composable/forget-api.js'

export default {
    name: 'reset-password',
    components: { MainHeader, MainFooter },
    setup(){
        const router = useRouter();

        const token = ref('');
        const email = ref('');
        const new_password = ref('');
        const cnf_password = ref('');
        const action_type = ref('submit');

        const pass_show_hide = ref('password');
        const reset_pass_show_hide = ref('password');

        const PassShowHideBtn = (type) => {
            pass_show_hide.value = type;
        }

        const ResetPassShowHideBtn = (type) => {
            reset_pass_show_hide.value = type;
        }

        onMounted(() => {
            email.value = router.currentRoute.value.query.email;
            token.value = router.currentRoute.value.query.token;
        })

        const formSubmit = async() => {
            action_type.value = 'button';
            if(new_password.value === cnf_password.value){
                let form = new FormData();
                form.append('token',token.value);
                form.append('email',email.value);
                form.append('password',new_password.value);
                const reset = await Api.resetpassword(form);
                if(reset.data){
                    router.push('/login');
                    action_type.value = 'submit';
                }
            }else {
                console.log('Not Match');
                action_type.value = 'submit';
            }
        }

        return {
            router,
            token,
            email,
            new_password,
            cnf_password,
            action_type,
            pass_show_hide,
            reset_pass_show_hide,
            PassShowHideBtn,
            ResetPassShowHideBtn,
            formSubmit,
        }
    }
}
</script>
<style lang="scss">
.form_area {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 60px 30px;
    .form_data {
        max-width: 400px;
        width: 100%;
        padding: 20px;
        box-shadow: 0 4px 20px rgba(0,0,0,.15);
        border-radius: 8px;
        .heading {
            color: #444;
            font-size: 20px;
            border-bottom: 1px solid #ddd;
            padding-bottom: 8px;
            margin-bottom: 16px;
        }
        form {
            ._gp {
                display: block;
                margin-bottom: 16px;
                label {
                    display: block;
                    margin-bottom: 4px;
                    color: #444;
                    font-size: 14px;
                    font-weight: 600;
                }
                input {
                    outline: none;
                    border: 1px solid #ddd;
                    height: 40px;
                    width: 100%;
                    padding: 4px 12px;
                    border-radius: 4px;
                }
                .hide_show {
                    position: relative;
                    img {
                        position: absolute;
                        top: 50%;
                        right: 10px;
                        max-width: 24px;
                        width: 100%;
                        transform: translateY(-50%);
                        cursor: pointer;
                    }
                }
            }
            .actions {
                button {
                    border: none;
                    outline: none;
                    cursor: pointer;
                    color: #222;
                    font-weight: 600;
                    padding: 8px 20px;
                    border-radius: 4px;
                    display: flex;
                    align-items: center;
                    column-gap: 4px;
                    margin-bottom: 20px;
                    font-size: 16px;
                    font-weight: 600;
                    color: #fff;
                    background: linear-gradient(45deg, #ffb1b1, #adc744);
                    transition: all 0.3s ease 0s;
                    -webkit-transition: all 0.3s ease 0s;
                    &:hover {
                        background: linear-gradient(45deg, #ff9e9e, #adca36);
                        transition: all 0.3s ease 0s;
                        -webkit-transition: all 0.3s ease 0s;
                    }
                }
            }
        }
    }
}


.loader {
  border: 3px solid #eee;
  border-left-color: transparent;
  border-radius: 50%;
  width: 16px;
  height: 16px;
  animation: spining 1s linear infinite;
}

@keyframes spining {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}
</style>