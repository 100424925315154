<template>
    <header>
        <div class="mobile_header">
            <div class="toggle">
                <div class="toggle_btn" @click="asideShowHideBtn">
                    <span></span>
                </div>
            </div>
            <div class="logo">
                <div class="image">
                    <router-link to="/" class="link">
                        <img src="@/assets/image/logo.jpeg">
                    </router-link>
                </div>
            </div>
            <div class="user">
                <div class="avatar">
                    <img @click="user_action = !user_action" src="@/assets/image/logo.jpeg">
                    <div class="actions" :class="{ 'show':user_action }">
                        <ul class="items">
                            <li class="item name">Hi! Dip</li>
                            <li class="item">
                                <router-link to="" class="link">Logout</router-link>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
        <div class="desktop_header" :class="{ 'slide': aside_action }">
            <div class="top">
                <div class="left">
                    <router-link to="" class="link">
                        <span class="icon">
                            <img src="@/assets/image/icon/email.png">
                        </span>
                        <span class="text">onlinezone781@gmail.com</span>
                    </router-link>
                </div>
                <div class="right">
                    <ul class="social">
                        <li class="item">
                            <router-link to="" class="link">
                                <span class="icon" title="Facebook"><img src="@/assets/image/icon/facebook.png"></span>
                            </router-link>
                        </li>
                        <li class="item">
                            <router-link to="" class="link">
                                <span class="icon" title="Twitter"><img src="@/assets/image/icon/twitter.png"></span>
                            </router-link>
                        </li>
                        <li class="item">
                            <router-link to="" class="link">
                                <span class="icon" title="Instagram"><img src="@/assets/image/icon/instagram.png"></span>
                            </router-link>
                        </li>
                        <li class="item">
                            <router-link to="" class="link">
                                <span class="icon" title="Linkedin"><img src="@/assets/image/icon/linkedin.png"></span>
                            </router-link>
                        </li>
                        <li class="item">
                            <router-link to="" class="link">
                                <span class="icon" title="WhatsApp"><img src="@/assets/image/icon/whatsapp.png"></span>
                            </router-link>
                        </li>
                    </ul>
                </div>
            </div>
            <div class="header">
                <div class="logo">
                    <router-link to="/" class="link">
                        <img src="@/assets/image/logo.jpeg">
                    </router-link>
                </div>
                <div class="menus">
                    <div class="head">
                        <div class="left">
                            <router-link to="/" class="link">MENU</router-link>
                        </div>
                        <div class="right">
                            <div class="close_btn" @click="asideShowHideBtn">
                                <span class="close"></span>
                            </div>
                        </div>
                    </div>
                    <ul class="items">
                        <li class="item">
                            <router-link to="/" class="link">Home</router-link>
                        </li>
                        <li class="item">
                            <a href="/#aboutus" class="link">About Us</a>
                        </li>
                        <li class="item">
                            <a href="#service" class="link">Service</a>
                            <ul class="submenu">
                                <li class="item">
                                    <router-link to="/rjsc" class="link">Rjsc</router-link>
                                </li>
                                <li class="item">
                                    <router-link to="/irc" class="link">Irc</router-link>
                                </li>
                            </ul>
                        </li>
                        <li class="item">
                            <a href="/#contact" class="link">Contact</a>
                        </li>
                        <li class="item" v-if="!userId">
                            <router-link to="/register" class="link">Register</router-link>
                        </li>
                        <li class="item" v-if="!userId">
                            <router-link to="/login" class="link">Login</router-link>
                        </li>
                        <li class="item" v-if="userId">
                            <a href="#" class="link">{{ userName }}</a>
                            <ul class="submenu left">
                                <li class="item">
                                    <router-link to="/profile" class="link">Profile</router-link>
                                </li>
                                <li class="item">
                                    <router-link to="" class="link" @click="Logout">Logout</router-link>
                                </li>
                            </ul>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
        <div class="overlay" :class="{ 'show': aside_action }" @click="asideShowHideBtn"></div>
    </header>
</template>
<script>
import { ref } from 'vue';
import AuthStore from "@/store/AuthStore.js"

export default {
    setup(){
        const user_action = ref(false);
        const aside_action = ref(false);

        const userId = localStorage.getItem('id');
        const userName = localStorage.getItem('name');

        const asideShowHideBtn = () => {
            aside_action.value =! aside_action.value
        }

        const { Logout } = AuthStore();


        return {
            user_action,
            aside_action,
            asideShowHideBtn,
            userId,
            userName,
            Logout
        }
    }
}
</script>
<style lang="scss">
header {
    .mobile_header {
        display: none;
    }
    .desktop_header {
        .top {
            height: 24px;
            background: linear-gradient(90deg,#70b741,#84d64e);
            display: flex;
            justify-content: space-between;
            padding: 0 20px;
            .left {
                display: flex;
                align-items: center;
                .link {
                    display: flex;
                    align-items: center;
                    column-gap: 8px;
                    text-decoration: none;
                    color: #000;
                    .icon {
                        width: 12px;
                        display: flex;
                        align-items: center;
                        img {
                            max-width: 100%;
                        }
                    }
                }
                .text {
                    color: #444;
                    font-size: 13px;
                }
            }
            .right {
                display: flex;
                align-items: center;
                .social {
                    list-style: none;
                    display: flex;
                    column-gap: 12px;
                    .link {
                        display: flex;
                        .icon {
                            width: 12px;
                            img {
                                max-width: 100%;
                            }
                        }
                    }
                }
            }
        }
        .header {
            height: 70px;
            width: 100%;
            background: linear-gradient(90deg,#41b782,#86d169);
            box-shadow: 0 4px 24px rgba(0,0,0,.15);
            display: flex;
            justify-content: space-between;
            padding: 0 20px;
            .logo {
                width: 50px;
                display: flex;
                align-items: center;
                .link {
                    display: flex;
                    img {
                        max-width: 100%;
                    }
                }
            }
            .menus {
                display: flex;
                align-items: center;
                .head {
                    display: none;
                }
                .items {
                    list-style: none;
                    display: flex;
                    column-gap: 8px;
                    .item {
                        padding: 4px;
                        position: relative;
                        .link {
                            color: #444;
                            text-decoration: none;
                            transition: all 0.3s ease 0s;
                            -webkit-transition: all 0.3s ease 0s;
                            &:hover {
                                color: #d89024;
                            }
                        }
                        &:hover {
                            & > .submenu {
                                visibility: visible;
                                opacity: 1;
                            }
                        }
                        & > .submenu {
                            position: absolute;
                            left: 0;
                            min-width: 160px;
                            width: 100%;
                            background-color: #fff;
                            border-radius: 8px;
                            box-shadow: 0 2px 8px 2px rgba(0,0,0,.15);
                            list-style: none;
                            z-index: 2;
                            visibility: hidden;
                            opacity: 0;
                            overflow: hidden;
                            &.left {
                                left: auto;
                                right: 0;
                            }
                            li {
                                padding: 0;
                                a {
                                    display: block;
                                    padding: 5px 20px;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}



$phone-width-small: 359px;
$phone-width: 575px;
$tablet-width: 767px;
$desktop-width: 1023px;

@media (max-width: #{$desktop-width}) {
}

@media (max-width: #{$tablet-width}) {
    header {
        .mobile_header {
            height: 50px;
            width: 100%;
            background: linear-gradient(90deg,#41b782,#86d169);
            box-shadow: 0 4px 24px rgba(0,0,0,.15);
            display: flex;
            align-items: center;
            padding: 0 20px;
            z-index: 999;
            .toggle,
            .user {
                width: 30%;
            }
            .toggle {
                display: flex;
                align-items: center;
                .toggle_btn {
                    width: 24px;
                    height: 24px;
                    cursor: pointer;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    span {
                        width: 20px;
                        height: 2px;
                        background: #fff;
                        display: inline-block;
                        position: relative;
                        &::before,
                        &::after {
                            position: absolute;
                            content: '';
                            width: 20px;
                            height: 2px;
                            left: 0;
                            background: #fff;
                            display: inline-block;
                        }
                        &::before {
                            top: -7px;
                        }
                        &::after {
                            bottom: -7px;
                        }
                    }
                }
            }
            .logo {
                width: 40%;
                display: flex;
                justify-content: center;
                .image {
                    width: 40px;
                    display: flex;
                    align-items: center;
                    .link {
                        display: flex;
                        img {
                            max-width: 100%;
                        }
                    }
                }
            }
            .user {
                display: flex;
                justify-content: end;
                .avatar {
                    width: 35px;
                    height: 35px;
                    border-radius: 50%;
                    background-color: #777;
                    position: relative;
                    img {
                        position: absolute;
                        width: 100%;
                        height: 100%;
                        border-radius: 50%;
                        cursor: pointer;
                    }
                    .actions {
                        position: absolute;
                        right: -15px;
                        top: 35px;
                        width: 120px;
                        background-color: #fff;
                        border-radius: 0 0 4px 4px;
                        box-shadow: 0 4px 20px rgba(0,0,0,.15);
                        z-index: 2;
                        display: none;
                        &.show {
                            display: block;
                        }
                        .items {
                            list-style: none;
                            padding: 8px 0;
                            .item {
                                color: #444;
                                font-weight: 600;
                                &.name {
                                    padding: 0 12px;
                                    margin-bottom: 8px;
                                }
                                .link {
                                    text-decoration: none;
                                    color: #444;
                                    font-weight: 400;
                                    padding: 4px 12px;
                                    font-size: 15px;
                                    display: block;
                                    &:hover {
                                        background-color: #f2f2f2;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
        .desktop_header {
            position: fixed;
            max-width: 0;
            width: 0;
            height: 100vh;
            top: 0;
            left: 0;
            background: linear-gradient(90deg,#41b782,#86d169);
            z-index: 999;
            display: flex;
            flex-direction: column-reverse;
            justify-content: space-between;
            visibility: hidden;
            overflow: hidden;
            z-index: 999;
            transition: all 0.3s ease 0s;
            -webkit-transition: all 0.3s ease 0s;
            &.slide {
                max-width: 240px;
                width: 100%;
                visibility: visible;
            }
            .top {
                flex-direction: column;
                row-gap: 8px;
                height: auto;
                background: transparent;
                padding: 8px 12px;
                .left {
                    justify-content: center;
                    .link {
                        .text {
                        }
                    }
                }
                .right {
                    justify-content: center;
                };
            }
            .header {
                flex-direction: column;
                height: auto;
                padding: 0;
                box-shadow: none;
                .logo {
                    display: none;
                }
                .menus {
                    flex-direction: column;
                    .head {
                        display: flex;
                        justify-content: space-between;
                        align-items: center;
                        height: 50px;
                        width: 100%;
                        padding: 4px 20px;
                        border-bottom: 1px solid #ddd;
                        .left {
                            .link {
                                color: #fff;
                                font-size: 16px;
                                font-weight: 600;
                                text-decoration: none;
                                letter-spacing: .8px;
                            }
                        }
                        .right {
                            .close_btn {
                                .close {
                                    position: relative;
                                    width: 20px;
                                    height: 20px;
                                    display: flex;
                                    justify-content: center;
                                    align-items: center;
                                    cursor: pointer;
                                    &::before, &::after {
                                        position: absolute;
                                        content: '';
                                        width: 16px;
                                        height: 2px;
                                        right: 0;
                                        background-color: #fff;
                                    }
                                    &::before {
                                        transform: rotate(45deg);
                                    }
                                    &::after {
                                        transform: rotate(-45deg);
                                    }
                                }
                            }
                        }
                    }
                    .items {
                        flex-direction: column;
                        width: 100%;
                        .item {
                            .link {
                                color: #fff;
                                padding: 4px 20px;
                                display: block;
                                text-align: center;
                            }
                            & > .submenu {
                                position: relative;
                                width: 100%;
                                background: transparent;
                                height: 0;
                            }
                            &:hover {
                                & > .submenu {
                                    height: fit-content;
                                }
                            }
                        }
                    }
                }
            }
        }
        .overlay {
            position: fixed;
            width: 100%;
            height: 100vh;
            top: 0;
            left: 0;
            background-color: rgba(0,0,0,.45);
            cursor: pointer;
            z-index: 5;
            display: none;
            &.show {
                display: block;
            }
        }
    }
}

@media (max-width: #{$phone-width}) {
}

@media (max-width: #{$phone-width-small}) {
}

</style>