<template>
    <main-header></main-header>
    <div class="slider">
        <div class="items">
            <div class="item" name="slide" v-for="(data,index) in slides" :key="index">
                <div class="image" v-show="currentSlide === index+1">
                    <img :src="data.image" alt="Slide">
                </div>
            </div>
            <div class="slide_arrow">
                <div class="arrow left" @click="prevSlide">
                    <img src="@/assets/image/icon/left.png" alt="Left">
                </div>
                <div class="arrow right" @click="nextSlide">
                    <img src="@/assets/image/icon/right.png" alt="Right">
                </div>
            </div>
            <div class="slide_dots">
                <div class="dot" v-for="(data,index) in slides" :key="index" :class="{'active': currentSlide === index+1}" @click="currentSlide = index+1"></div>
            </div>
        </div>
    </div>


    <div id="aboutus" class="about_area">
        <div class="about_content">
            <div class="heading">
                <h2>About Us</h2>
            </div>
            <!-- <h4>Web Developer</h4> -->
            <p>
                OnlineZone is one of the leading consulting firms in Bangladesh, and it has a long history of working with businesses to develop sustainable solutions.
            </p>
            <router-link to="#" class="link">Watch Video</router-link>
        </div>
        <div class="about_img">
            <img src="@/assets/image/about-us.png" alt="Info Image">
        </div>
    </div>


    <div class="service_page" id="service" v-if="service_data">
        <div class="heading">
            <h2>Our Services</h2>
        </div>
        <div class="items">
            <div class="item" v-for="(data,index) in service_data" :key="index">
                <div class="icon">
                    <div class="_i">
                        <img v-if="data.image" :src="data.image" :alt="data.slug">
                    </div>
                    <span class="_t"></span>
                    <span class="_b"></span>
                </div>
                <div class="content">
                    <!-- <router-link :to="'/'+data.slug" class="title">{{ data.title }}</router-link> -->
                    <router-link to="" class="title">{{ data.title }}</router-link>
                    <div class="txt" v-html="data.description.replace(/\r?\n/g,'<br/>')"></div>
                </div>
            </div>
        </div>
    </div>


    <div class="contact_area" id="contact">
        <div class="contact_info">
            <h2>Get In Touch!</h2>
            <span class="hr"></span>
            <ul class="address">
                <li>3/D, kabbokas super Market, (lift-5) Kawran Bazar, Dhaka-1215 <span>&#8592;</span></li>
                <li>+880 1912-024781 <span>&#8592;</span></li>
                <li>onlinezone781@gmail.com <span>&#8592;</span></li>
            </ul>
            <div class="social">
                <a href="#"></a>
            </div>
        </div>
        <form class="contact_form">
            <p class="msg">
                <span class="error" v-if="errMsg">{{ errMsg }}</span>
                <span class="success" v-if="successMsg">{{ successMsg }}</span>
            </p>
            <input
                type="text"
                name="fullname"
                v-model.trim="fullname"
                class="half"
                placeholder="Full Name"
            />
            <input
                type="number"
                name="phone"
                v-model.trim="phone"
                class="half last"
                placeholder="Phone Number"
            />
            <input
                type="text"
                name="email"
                v-model.trim="email"
                class="full"
                placeholder="example@gmail.com"
            />
            <input
                type="text"
                name="subject"
                v-model.trim="subject"
                class="full"
                placeholder="Subject"
            />
            <textarea
                rows="5"
                name="message"
                v-model.trim="message"
                class="full"
                placeholder="message"
            ></textarea>
            <button type="submit" class="button" @click.prevent="send">Send</button>
        </form>
    </div>
    <main-footer></main-footer>
</template>
<script>
import { ref, onMounted } from 'vue';
import MainHeader from "./layout/MainHeader.vue"
import MainFooter from "./layout/MainFooter.vue"
import Axios from 'axios'

export default {
    name: "home-page",
    components: { MainHeader, MainFooter },
    setup() {
        const currentSlide = ref(1);
        const slides = ref([]);
        const service_data = ref([]);

        const errMsg = ref('');
        const successMsg = ref('');
        const fullname = ref('');
        const phone = ref('');
        const email = ref('');
        const subject = ref('');
        const message = ref('');



        const autoPlay = () => {
            setInterval(() => {
                nextSlide();
            },5000)
        }

        onMounted(async()=>{
            autoPlay();


            Axios.get('/api/services')
            .then(response => {
                if(response.status == 200){
                    service_data.value = response.data;
                }
            })

            Axios.get('/api/sliders')
            .then(response => {
                if(response.status == 200){
                    slides.value = response.data;
                }
            })
        });


        const nextSlide = () => {
            if(currentSlide.value === slides.value.length){
                currentSlide.value = 1;
            }else{
                currentSlide.value += 1;
            }
        }

        const prevSlide = () => {
            if(currentSlide.value === 1){
                currentSlide.value = slides.value.length;
            }else{
                currentSlide.value -= 1;
            }
        }

        const send = () => {
            if(fullname.value == ''){
                errMsg.value = 'Full name is Empty!';
            }else if(phone.value == ''){
                errMsg.value = 'Phone Number is Empty!';
            }else if(email.value == ''){
                errMsg.value = 'Email is Empty!';
            }else if(subject.value == ''){
                errMsg.value = 'Subject is Empty!';
            }else if(message.value == ''){
                errMsg.value = 'Your Message is Empty!';
            }else {
                let form = new FormData();
                form.append("fullname",fullname.value)
                form.append("phone",phone.value)
                form.append("email",email.value)
                form.append("subject",subject.value)
                form.append("message",message.value)
                Axios.post('/api/contact-form',form)
                .then(response => {
                    if(response.status == 200){
                        errMsg.value = '';
                        fullname.value == '';
                        phone.value == '';
                        email.value == '';
                        subject.value == '';
                        message.value == '';
                        successMsg.value = response.data;
                    }
                })
            }
        }

        const paymentBtn = () => {
            window.location.href = 'http://localhost:8000/api/payment'
            // Axios.get('/api/payment')
            // .then(response => {
            //     if(response.status == 200){
            //         console.log(response.data);
            //     }
            // })
        }

        return {
            currentSlide,
            slides,
            nextSlide,
            prevSlide,
            autoPlay,
            service_data,

            errMsg,
            successMsg,
            fullname,
            phone,
            email,
            subject,
            message,
            send,

            paymentBtn,
        }
    },
};
</script>

<style lang="scss">
/* ========================================= */
/* Slider Css */
.slider {
    .items {
        position: relative;
        width: 100%;
        height: 450px;
        .item {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            .image {
                width: 100%;
                height: 100%;
                img {
                    width: 100%;
                    height: 100%;
                }
            }
        }
    }
    .slide_arrow {
        position: absolute;
        width: 100%;
        top: 50%;
        transform: translateY(-50%);
        .arrow {
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            background-color: #00000061;
            width: 30px;
            height: 30px;
            border-radius: 50%;
            display: flex;
            align-items: center;
            justify-content: center;
            cursor: pointer;
            transition: all 0.3s ease 0s;
            -webkit-transition: all 0.3s ease 0s;
            &:hover {
                background-color: #4f4f4f;
            }
            &.left {
                left: 12px;
            }
            &.right {
                right: 12px;
            }
            img {
                max-width: 12px;
            }
        }
        
    }
    .slide_dots {
        position: absolute;
        left: 50%;
        bottom: 12px;
        transform: translateX(-50%);
        display: flex;
        column-gap: 12px;
        z-index: 1;

        .dot {
            width: 16px;
            height: 16px;
            background-color: #777;
            border-radius: 50%;
            cursor: pointer;
            transition: all 0.3s ease 0s;
            -webkit-transition: all 0.3s ease 0s;

            &:hover {
                background-color: #444;
            }
        }

        .active {
            background-color: #f19024;

            &:hover {
                background-color: #f19024;
            }
        }
    }
}

.slide-enter-active,
.slide-leave-active {
    transition: opacity 0.7s ease-in-out;
}
.slide-enter-from,
.slide-leave-to {
    opacity: 0;
}
.slide-enter-to,
.slide-leave-from {
    opacity: 1;
}
    

/* ========================================= */
/* About Css */
.about_area {
    display: flex;
    background-color: #fff;
    padding: 30px 60px;
    .about_content {
        flex: 6;
        padding-right: 40px;
        .heading {  
            margin-bottom: 20px;
            h2 {
                position: relative;
                z-index: 1;
                &::before,&::after {
                    position: absolute;
                    content: "";
                    width: 40px;
                    height: 25px;
                    background-color: rgba(241,144,36,.439);
                    z-index: -1;
                }
                &::before {
                    top: -10px;
                    left: 7px;
                    border-radius: 70px 20px 70px 8px;
                    transform: rotate(-5deg);
                }
                &::after {
                    bottom: -16px;
                    left: 40px;
                    border-radius: 8px 70px 20px 70px;
                    transform: rotate(5deg);
                }
            }
        }
        h1 {
            font-size: 40px;
            margin-bottom: 20px;
        }
        h4 {
            margin-bottom: 18px;
        }
        p {
            font-size: 16px;
            color: #777;
            margin-bottom: 12px;
            text-align: justify;
        }
        .link {
            color: #222;
            font-weight: 600;
            padding: 12px 20px;
            border-radius: 4px;
            display: inline-block;
            margin-top: 20px;
            background: linear-gradient(45deg, #ffb1b1, #adc744);
            transition: all 0.3s ease 0s;
            -webkit-transition: all 0.3s ease 0s;
            &:hover {
                background: linear-gradient(45deg, #ff9e9e, #adca36);
                transition: all 0.3s ease 0s;
                -webkit-transition: all 0.3s ease 0s;
            }
        }
    }
    .about_img {
        flex: 6;
        border-radius: 40px 110px 80px 230px;
        overflow: hidden;
        img {
            max-width: 100%;
        }
    }
}


/* ========================================= */
/* Service Css */
.service_page {
    margin-top: 60px;
    padding: 40px;
    .heading {  
        padding: 8px 20px;
        margin-bottom: 20px;
        h2 {
            display: table;
            margin: auto;
            position: relative;
            z-index: 1;
            &::before,&::after {
                position: absolute;
                content: "";
                width: 40px;
                height: 25px;
                background-color: rgba(241,144,36,.439);
                z-index: -1;
            }
            &::before {
                top: -10px;
                left: 7px;
                border-radius: 70px 20px 70px 8px;
                transform: rotate(-5deg);
            }
            &::after {
                bottom: -16px;
                left: 40px;
                border-radius: 8px 70px 20px 70px;
                transform: rotate(5deg);
            }
        }
    }
    .items {
        display: grid;
        grid-template-columns: repeat(4, minmax(150px, 1fr));
        column-gap: 30px;
        row-gap: 40px;
        .item {
            border-radius: 4px;
            padding: 40px 20px;
            overflow: hidden;
            position: relative;
            transition: all 0.3s ease 0s;
            -webkit-transition: all 0.3s ease 0s;
            &:hover {
                background-color: #f2f2f2;
                transform: translateY(-8px);
                box-shadow: 0 4px 20px rgba(0, 0, 0, 0.15);
                .icon {
                    ._t {
                        &::before {
                            width: 50%;
                            right: 0%;
                            transform: translateX(0%);
                            background-color: #5648ed;
                        }
                        &::after {
                            width: 50%;
                            left: 0%;
                            transform: translateX(0%);
                            background-color: #5648ed;
                        }
                    }
                    ._b {
                        &::before {
                            width: 50%;
                            right: 0%;
                            transform: translateX(0%);
                            background-color: #5648ed;
                        }
                        &::after {
                            width: 50%;
                            left: 0%;
                            transform: translateX(0%);
                            background-color: #5648ed;
                        }
                    }
                }
            }
            &::after {
                position: absolute;
                content: "";
                width: 0%;
                height: 3px;
                bottom: 0;
                left: 0;
                background-color: transparent;
                transition: all 0.3s ease 0s;
                -webkit-transition: all 0.3s ease 0s;
            }
            &:hover::after {
                width: 100%;
                background-color: #5648ed;
            }
            .icon {
                display: table;
                margin: auto;
                ._i {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    width: 60px;
                    height: 60px;
                    border-radius: 50%;
                    background: linear-gradient(90deg,#86d169 ,#41b782);
                    overflow: hidden;
                    img {
                        max-width: 100%;
                    }
                }
                ._t {
                    width: 50px;
                    height: 2px;
                    background-color: #999;
                    display: table;
                    margin: auto;
                    margin-top: 4px;
                    position: relative;
                    &::before {
                        position: absolute;
                        content: '';
                        width: 0%;
                        height: 2px;
                        right: 50%;
                        transform: translateX(-50%);
                        background-color: transparent;
                        transition: all 0.5s ease 0s;
                        -webkit-transition: all 0.5s ease 0s;
                    }
                    &::after {
                        position: absolute;
                        content: '';
                        width: 3px;
                        height: 2px;
                        left: 50%;
                        transform: translateX(-50%);
                        background-color: transparent;
                        transition: all 0.5s ease 0s;
                        -webkit-transition: all 0.5s ease 0s;
                    }
                }
                ._b {
                    width: 30px;
                    height: 2px;
                    background-color: #999;
                    display: table;
                    margin: auto;
                    margin-top: 4px;
                    position: relative;
                    &::before {
                        position: absolute;
                        content: '';
                        width: 0%;
                        height: 2px;
                        right: 50%;
                        transform: translateX(-50%);
                        background-color: transparent;
                        transition: all 0.5s ease 0s;
                        -webkit-transition: all 0.5s ease 0s;
                    }
                    &::after {
                        position: absolute;
                        content: '';
                        width: 3px;
                        height: 2px;
                        left: 50%;
                        transform: translateX(-50%);
                        background-color: transparent;
                        transition: all 0.5s ease 0s;
                        -webkit-transition: all 0.5s ease 0s;
                    }
                }
            }
            .content {
                margin-top: 16px;
                text-align: center;
                .title {
                    font-size: 20px;
                    font-weight: 600;
                    color: #444;
                    margin-bottom: 12px;
                }
                .txt {
                    font-size: 14px;
                    color: #777;
                }
            }
        }
    }
}



/* ======================================== */
/* Contact Css */
.contact_area {
    display: flex;
    background-color: #fff;
    padding: 30px 60px;
    .contact_form,
    .contact_info {
        flex: 6;
    }
    .contact_info {
        text-align: right;
        padding-right: 28px;
        span.hr {
            width: 100px;
            height: 2px;
            background-color: #444;
            display: inline-block;
            margin: 12px 0;
        }
        .address {
            list-style: none;
            li {
                color: #444;
                line-height: 28px;
                span {
                    font-size: 18px;
                }
            }
        }
    }
    .contact_form {
        input, textarea {
            padding: 12px;
            border: 1px solid #d8d8d8;
            margin-bottom: 16px;
            border-radius: 4px;
            outline: none;
            &:focus {
                border: 1px solid #adca36;
            }
            &.valid {
                border-color: tomato;
            }
        }
        .half {
            width: 48%;
            float: left;
            margin-right: 4%;
            &.last {
                margin-right: 0;
            }
        }
        .full {
            width: 100%;
        }
        .button {
            border: none;
            outline: none;
            cursor: pointer;
            color: #222;
            font-weight: 600;
            padding: 12px 20px;
            border-radius: 4px;
            display: inline-block;
            background: linear-gradient(45deg, #ffb1b1, #adc744);
            transition: all 0.3s ease 0s;
            -webkit-transition: all 0.3s ease 0s;
            &:hover {
                background: linear-gradient(45deg, #ff9e9e, #adca36);
                transition: all 0.3s ease 0s;
                -webkit-transition: all 0.3s ease 0s;
            }
        }
    }
}


    
$phone-width-small: 359px;
$phone-width: 575px;
$tablet-width: 767px;
$desktop-width: 1023px;

@media (max-width: #{$desktop-width}) {
    .slider {
        .items {
            height: 330px;
        }
    }
}

@media (max-width: #{$tablet-width}) {
    .slider {
        .items {
            height: 300px;
        }
        .slide_dots {
            .dot {
                width: 12px;
                height: 12px;
            }
        }
    }
    .about_area {
        display: flex;
        flex-direction: column;
        row-gap: 20px;
        padding: 20px;
        .about_content {
            flex: 12;
            padding-right: 0;
        }
        .about_img {
            flex: 12;
            border-radius: 0px;
        }
    }
    .service_page {
        margin-top: 20px;
        .items {
            grid-template-columns: repeat(2, minmax(150px, 1fr)) !important;
        }
    }
}

@media (max-width: #{$phone-width}) {
    .slider {
        .items {
            height: 230px;
        }
    }
    .service_page {
        padding: 20px;
        .items {
            grid-template-columns: repeat(1, minmax(150px, 1fr)) !important;
            row-gap: 20px;
            .item {
                border: 1px solid #f0f0f0;
                border-radius: 4px;
            }
        }
    }
    .contact_area {
        display: flex;
        flex-direction: column;
        padding: 20px;
        row-gap: 20px;
        .contact_form,
        .contact_info {
            flex: 12;
        }
        .contact_info {
            text-align: center;
            padding-right: 0px;
        }
    }
}

@media (max-width: #{$phone-width-small}) {
    .slider {
        .items {
            height: 200px;
        }
    }
}

</style>