<template>
    <main-header></main-header>

    <div class="page-wrapper">
        <div class="content">
            <div class="heading">
                <h2>Profile</h2>
            </div>
            <div class="text">
                <p><strong>Name : </strong>{{ userName }}</p>
                <p><strong>Name : </strong>{{ userEmail }}</p>
            </div>

            <div class="heading" style="margin-top: 20px;">
                <h2>Account Delete</h2>
            </div>
            <form @submit.prevent="formSubmit" style="margin-top: 12px;">
                <div class="actions">
                    <button :type="action_type"><div class="loader" v-if="action_type == 'button'"></div> Account Delete</button>
                </div>
            </form>
        </div>
    </div>
    
    <main-footer></main-footer>
</template>
<script>
import { ref } from 'vue';
import MainHeader from "@/views/layout/MainHeader.vue"
import MainFooter from "@/views/layout/MainFooter.vue"
import AuthStore from "@/store/AuthStore.js"
import Api from '@/views/composable/profile.js'

export default {
    name: "profile-page",
    components: { MainHeader, MainFooter },
    setup(){
        const userId = localStorage.getItem('id');
        const userName = localStorage.getItem('name');
        const userEmail = localStorage.getItem('email');
        
        const action_type = ref('submit');

        const { Logout } = AuthStore();

        const formSubmit = async () => {
            action_type.value = 'button';

            let form = new FormData();
            form.append('id',userId);
            form.append('email',userEmail);
            try{
                const sendemail = await Api.accountDelete(form);
                action_type.value = 'submit';
                if(sendemail){
                    Logout();
                }
            } catch(err) {
                console.log(err.response.data.message);
            }
        }

        return {
            userId,
            userName,
            userEmail,
            formSubmit,
            Logout 
        }
    }

};
</script>

<style lang="scss">
.page-wrapper {
    display: flex;
    justify-content: center;
    padding: 40px 20px;
    .content {
        max-width: 800px;
        width: 100%;
        .heading {
            h2 {
                font-size: 18px;
            }
            p {
                font-size: 14px;
                color: #444;
            }
        }
        .lists {
            list-style: none;
            border: 1px solid #ddd;
            border-radius: 4px;
            margin-top: 20px;
            .list {
                display: flex;
                column-gap: 12px;
                border-bottom: 1px solid #ddd;
                padding: 6px 12px;
                &.head {
                    .title, .price {
                        font-size: 12px;
                        font-weight: 600;
                    }
                }
                &:last-child {
                    border-bottom: 0px solid transparent;
                }
                .title {
                    width: 60%;
                }
                .price {
                    width: 40%;
                }
                .title, .price {
                    color: #444;
                    font-size: 12px;
                }
            }
        }
        form {
            ._gp {
                display: block;
                margin-bottom: 16px;
                label {
                    display: block;
                    margin-bottom: 4px;
                    color: #444;
                    font-size: 14px;
                    font-weight: 600;
                }
                input {
                    outline: none;
                    border: 1px solid #ddd;
                    height: 40px;
                    width: 100%;
                    padding: 4px 12px;
                    border-radius: 4px;
                }
                .hide_show {
                    position: relative;
                    img {
                        position: absolute;
                        top: 50%;
                        right: 10px;
                        max-width: 24px;
                        width: 100%;
                        transform: translateY(-50%);
                        cursor: pointer;
                    }
                }
            }
            .actions {
                button {
                    border: none;
                    outline: none;
                    cursor: pointer;
                    color: #222;
                    font-weight: 600;
                    padding: 8px 20px;
                    border-radius: 4px;
                    display: flex;
                    align-items: center;
                    column-gap: 4px;
                    margin-bottom: 20px;
                    font-size: 16px;
                    font-weight: 600;
                    color: #fff;
                    background: linear-gradient(45deg, #ffb1b1, #adc744);
                    transition: all 0.3s ease 0s;
                    -webkit-transition: all 0.3s ease 0s;
                    &:hover {
                        background: linear-gradient(45deg, #ff9e9e, #adca36);
                        transition: all 0.3s ease 0s;
                        -webkit-transition: all 0.3s ease 0s;
                    }
                }
            }
        }
    }
}
</style>