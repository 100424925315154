const AdminAuthModule = () => import("./AdminAuthModule.vue");
const Login = () => import("./views/LoginPage.vue");
const ForgetPassword = () => import("./views/forget/ForgetPassword.vue");
const ResetPassword = () => import("./views/forget/ResetPassword.vue");

const moduleRoute = {
  path: "/admin/login",
  component: AdminAuthModule,
  children: [
    { 
      path:"/admin/login", 
      name:"admin-login",
      component:Login
    },
    { 
      path:"/admin/forget-password", 
      name:"admin-forget-password",
      component:ForgetPassword
    },
    { 
      path:"/admin/reset-password", 
      name:"admin-reset-password",
      component:ResetPassword
    },
  ]
};

export default router => {
  router.addRoute(moduleRoute);
};

