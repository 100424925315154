<template>
    <main-header></main-header>

    <div class="rjsc">
        <div class="content">
            <div class="heading">
                <h2>RJSC GOVT FEE :</h2>
                <p>Private & Public Company </p>
            </div>
            <ul class="lists">
                <li class="list head">
                    <div class="title">Authorized Capital</div>
                    <div class="price">RJSC GOVT FEE</div>
                </li>
                <li class="list" v-for="(data,index) in get_data" :key="index">
                    <div class="title">{{ data.title }}</div>
                    <div class="price">{{ data.price }}</div>
                </li>
            </ul>
        </div>
    </div>
    
    <main-footer></main-footer>
</template>
<script>
import MainHeader from "./layout/MainHeader.vue"
import MainFooter from "./layout/MainFooter.vue"
import Api from "./composable/rjsc-api.js"
import { ref, onMounted } from "vue";
export default {
    name: "rjsc-page",
    components: { MainHeader, MainFooter },
    setup() {
        const get_data = ref([]);

        onMounted( async() => {
            const getData = await Api.getData();
            get_data.value = getData.data;
        })

        return {
            get_data
        }
    },

};
</script>

<style lang="scss">
.rjsc {
    display: flex;
    justify-content: center;
    padding: 40px 20px;
    .content {
        max-width: 800px;
        width: 100%;
        .heading {
            h2 {
                font-size: 18px;
            }
            p {
                font-size: 14px;
                color: #444;
            }
        }
        .lists {
            list-style: none;
            border: 1px solid #ddd;
            border-radius: 4px;
            margin-top: 20px;
            .list {
                display: flex;
                column-gap: 12px;
                border-bottom: 1px solid #ddd;
                padding: 6px 12px;
                &.head {
                    .title, .price {
                        font-size: 12px;
                        font-weight: 600;
                    }
                }
                &:last-child {
                    border-bottom: 0px solid transparent;
                }
                .title {
                    width: 60%;
                }
                .price {
                    width: 40%;
                }
                .title, .price {
                    color: #444;
                    font-size: 12px;
                }
            }
        }
    }
}
</style>