<template>
    <main-header></main-header>

    <div class="page-wrapper">
        <div class="content">
            <div class="heading">
                <h2>Terms and Condition</h2>
            </div>
            <div class="text">
                <p>Your First Payment Pay and try login!!</p>
            </div>
        </div>
    </div>
    
    <main-footer></main-footer>
</template>
<script>
import MainHeader from "./layout/MainHeader.vue"
import MainFooter from "./layout/MainFooter.vue"

export default {
    name: "terms-condition",
    components: { MainHeader, MainFooter },
    setup() {
        
    },

};
</script>

<style lang="scss">
.page-wrapper {
    display: flex;
    justify-content: center;
    padding: 40px 20px;
    .content {
        max-width: 800px;
        width: 100%;
        .heading {
            h2 {
                font-size: 18px;
            }
            p {
                font-size: 14px;
                color: #444;
            }
        }
        .lists {
            list-style: none;
            border: 1px solid #ddd;
            border-radius: 4px;
            margin-top: 20px;
            .list {
                display: flex;
                column-gap: 12px;
                border-bottom: 1px solid #ddd;
                padding: 6px 12px;
                &.head {
                    .title, .price {
                        font-size: 12px;
                        font-weight: 600;
                    }
                }
                &:last-child {
                    border-bottom: 0px solid transparent;
                }
                .title {
                    width: 60%;
                }
                .price {
                    width: 40%;
                }
                .title, .price {
                    color: #444;
                    font-size: 12px;
                }
            }
        }
    }
}
</style>